import React from "react";
import Helmet from "react-helmet";
import iosImage from "../../../assets/images/IOS.svg";
import value1 from "../../../assets/images/Picture1.png";
import value2 from "../../../assets/images/Picture2.png";
import value3 from "../../../assets/images/Picture3.png";
import value4 from "../../../assets/images/Picture4.png";
import value5 from "../../../assets/images/Picture5.png";
import value6 from "../../../assets/images/Picture6.png";
import value7 from "../../../assets/images/Picture7.png";
import value8 from "../../../assets/images/Picture8.png";
import slotLogo from "../../../assets/images/slotLogo.svg";
import slide from "../../../assets/images/landingBackground.jpg";
import googlePlayImage from "../../../assets/images/googlePlay.svg";
import "../../../assets/vendor/animate.css/animate.min.css";
import "../../../assets/vendor/swiper/swiper-bundle.min.css";
import "../../../assets/vendor/boxicons/css/boxicons.min.css";
import "../../../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../../../assets/vendor/glightbox/css/glightbox.min.css";
import "../../../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../../../style/general/newLanding.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState } from 'react';


export default function NewLanding() {
  const [question, setQuestion] = useState('');

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleEmailClick = () => {
    const emailAddress = 'cc@slotyourspace.com';
    const subject = 'New Question';
    const body = encodeURIComponent(question);

    window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  };


  return (
    <div>
      <section id="topbar" className="new d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope-fill" />
            <a href="mailto:CC@slotyourspace.com">CC@slotyourspace.com</a>
            <i className="bi bi-phone-fill phone-icon" /> +968 94045745
          </div>
          <div className="social-links d-none d-md-block">
            <a
              href="https://x.com/slot_yourspace?s=21&t=5XOvEQvoW7OWH0pEzcxFIw"
              className="twitter"
            >
              <i className="bi bi-twitter" />
            </a>
            <a
              href="https://instagram.com/slot_yourspace?igshid=MzRlODBiNWFlZA=="
              className="instagram"
            >
              <i className="bi bi-instagram" />
            </a>
          </div>
        </div>
      </section>

      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <img src={slotLogo} width="80"></img>
          <nav id="navbar1" className="navbar1">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/signup">
                  Registration
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="/login">
                  Login
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar1 */}
        </div>
      </header>

      {/* ======= slides Section ======= */}
      <section className="new" id="hero">
        <div
          id="heroCarousel"
          data-bs-interval={5000}
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <ol className="carousel-indicators" id="hero-carousel-indicators" />
          <div className="carousel-inner" role="listbox">
            {/* Slide 1 */}
            <div
              className="carousel-item active"
              style={{ backgroundImage: "url(" + slide + ")" }}
            >
              <div className="carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Welcome to <span>Slot</span>
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Solution consists of mobile App & management platform. Slot
                    enables to utilize existing spaces “ we call them
                    White-Spaces” into functional space like co-working space,
                    coaching space, study space and social gathering space.
                  </p>
                  <a
                    href="https://apps.apple.com/om/app/slot-places-reservations/id1620726952"
                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                  >
                    <img src={iosImage} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.slotyourspace.app&hl=en&gl=US"
                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                  >
                    <img src={googlePlayImage} />
                  </a>
                </div>
              </div>
            </div>
            {/* Slide 2 */}
            <div
              className="carousel-item"
              style={{ backgroundImage: "url(" + slide + ")" }}
            >
              <div className="carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Join Us
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Slot works behind the scenes to bring in more clients &
                    boosts your sales.
                  </p>
                  <a
                    href="/signup"
                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                  >
                    Register With Us
                  </a>
                </div>
              </div>
            </div>
            {/* Slide 3 */}
            <div
              className="carousel-item"
              style={{ backgroundImage: "url(" + slide + ")" }}
            >
              <div className="carousel-container">
                <div className="container">
                  <p className="animate__animated animate__fadeInUp">
                    Slot benefits individuals , corporates , coaches and space
                    owners at once. It enable all the customers to book their
                    preferable space in advance at their convenient time and
                    location.
                  </p>
                  <a
                    href="#about"
                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#heroCarousel"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bi bi-chevron-left"
              aria-hidden="true"
            />
          </a>
          <a
            className="carousel-control-next"
            href="#heroCarousel"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bi bi-chevron-right"
              aria-hidden="true"
            />
          </a>
        </div>
      </section>

      <section
        id="featured-services"
        className="new featured-services section-bg"
      >
        <div className="container">
          <div className="row no-gutters">
            <h2
              className="animate__animated animate__fadeInDown"
              style={{ marginBottom: "35px" }}
            >
              Slot Values:
            </h2>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="icon">
                  <img style={{ width: "80px" }} src={value1}></img>
                </div>
                <h4 className="title">
                  <a href>Create a better guest experience</a>
                </h4>
                <p className="description">
                  Reducing customer waiting time, which allow them to be served
                  immediately and avoid turning away guests or crowding your
                  host stand by offering a waitlist.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div>
                  <img
                    style={{ width: "80px", marginBottom: "15px" }}
                    src={value2}
                  ></img>
                </div>
                <h4 className="title">
                  <a href>Limit “Laptop Hobos”</a>
                </h4>
                <p className="description">
                  By controlling on cafe hobo’s expenditures.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="icon">
                  <img src={value3}></img>
                </div>
                <h4 className="title">
                  <a href>Cost control</a>
                </h4>
                <p className="description">Monetize White-Spaces</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="icon">
                  <img src={value4}></img>
                </div>
                <h4 className="title">
                  <a href>Seat in</a>
                </h4>
                <p className="description">
                  Fill tables that would have been left empty instead.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="icon">
                  <img src={value5}></img>
                </div>
                <h4 className="title">
                  <a href>Scalability</a>
                </h4>
                <p className="description">
                  it will help you expand easily with no restriction, no need to
                  think of having a large and sizeable office due to having new
                  employee’s.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div>
                  <img
                    style={{ width: "40px", marginBottom: "15px" }}
                    src={value6}
                  ></img>
                </div>
                <h4 className="title">
                  <a href>Contributes on CO2 emission reduction</a>
                </h4>
                <p className="description">
                  utilizes existing spaces which –indirectly- leads to reduce
                  amount of office spaces construction.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="icon">
                  <img src={value7}></img>
                </div>
                <h4 className="title">
                  <a href>Flexibility</a>
                </h4>
                <p className="description">
                  allows employees to feel empowered to work the way they want
                  to, wherever they want to. Slot aims to offer dynamic &
                  flexible co-working space.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="icon">
                  <img className="bi bi-calendar4-week" src={value8} />
                </div>
                <h4 className="title">
                  <a href>Space availability assurance</a>
                </h4>
                <p className="description">
                  Assure space availability and enables event advance planning
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="faq-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 offset-md-3">

              <div class="faq-title text-center pb-3">
                <h2>FAQ</h2>
              </div>


              <div class="faq-title-ask text-center ">
                <p style={{ fontSize: '25px' }}>Ask us Anything</p>
                <form >
                  <p style={{ fontSize: '18px' }}>
                    Have any question? We're here to assist you. </p>
                  <input style={{ width: '250px', marginBottom: '20px' }} type="text" value={question} onChange={handleInputChange} />
                  <button type="button" onClick={handleEmailClick}>
                    <i class="search-icon fa fa-search"></i>
                  </button>
                </form>
              </div>


            </div>
            <div className="col-md-6 offset-md-3">
              <div className="faq" id="accordion" style={{ marginBottom: '25px' }}>


                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">1</span>What happens if a customer does not show up for their reservation?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            We recommend that you have your own policy in place to handle this situation. </Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>

                </div>

                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">2</span>
                            What if a customer wants to increase the booking hours for their reservation?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>If a customer wants to extend their reservation, they can simply re-book through the app. </Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>

                </div>

                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">3</span>
                            Can a customer cancel their reservation?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            Currently, cancellation is not an option. </Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>

                </div>


                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">4</span>
                            How can we remind customers of the time left of their booking? </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            They will receive a notification from the app itself.
                          </Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>

                </div>


                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">5</span>
                            How can we intimate a customer who is sitting in a space that is reserved for another customer?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            We advise to keep the slot sign there, so customer immediately be aware this table is only for reservation</Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>

                </div>


                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">6</span>
                            Can I integrate the app with my other software, such as my POS system?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            In the near future.</Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>

                </div>


                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">7</span>
                            What information can I see on the dashboard?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            The dashboard provides information about your performance, including the number of reservations and total income. It can also help you understand your customer. </Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>
                </div>

                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">8</span>
                            How can I use the dashboard to improve my customer service?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            The dashboard can help you identify peak times, manage wait times, and adjust customer spending time. This information can help you staff your operation appropriately.
                          </Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>
                </div>

                <div className="card">
                  <Accordion>
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <span className="badge">9</span>
                            How can I use the dashboard to forecast my staffing needs?
                          </Typography>
                        </AccordionSummary>
                      </div>
                    </div>
                    <div >
                      <div class="card-body">
                        <AccordionDetails>
                          <Typography>
                            by providing insights into your historical reservation data and seasonal trends. By analyzing this information, you can identify your busiest days and times and ensure that you have the right number of staffs on hand to provide excellent customer service and maximize your profits.
                          </Typography>
                        </AccordionDetails>
                      </div>
                    </div>
                  </Accordion>
                </div>


              </div>
            </div>
          </div>
        </div >
      </section >




      <footer id="footer">
        <div class="container">
          <a
            className="nav-link scrollto"
            href="privacy-policy"
            style={{ display: "inline-block" }}
          >
            Privacy Policy{" "}
          </a>{" "}
          &nbsp; | &nbsp;{" "}
          <a
            className="nav-link scrollto"
            href="terms-conditions"
            style={{ display: "inline-block" }}
          >
            Terms & Conditions
          </a>
        </div>
      </footer>
      <Helmet>
        <script
          type="text/javascript"
          src="../../public/js/bootstrap/js/bootstrap.bundle.min.js"
        ></script>
        <script
          type="text/javascript"
          src="../../public/js/glightbox/js/glightbox.min.js"
        ></script>
        <script
          type="text/javascript"
          src="../../public/js/isotope-layout/isotope.pkgd.min.js"
        ></script>
        <script
          type="text/javascript"
          src="../../public/js/swiper/swiper-bundle.min.js"
        ></script>
        <script src="./js/main.js" type="text/javascript" />
      </Helmet>
    </div >
  );
}
